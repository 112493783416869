import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import VerifyToken from './context/VerifyTokenContext';

// Para desarrollo en tiempo real o build de producción
let jwtToken = null;
let divName = null;
let messageWelcome = null;

if (process.env.NODE_ENV === 'development') {
  // En desarrollo: Puedes definir tus valores manualmente o de otra fuente
  jwtToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2JvdCI6OCwiaWF0IjoxNzI5NjU2NDcxLCJleHAiOjE3NjA3NjA0NzEsImF1ZCI6Imh0dHBzOi8vYXBwLmZsdXJpeC5haSJ9.Gklnv2ECN8I8OrpU6dN8d4_T0QFes2UJLeEtX7nONeI';
  divName = 'root'; // Este es el div en el que React se renderiza en index.html
  messageWelcome = 'Hola soy un asistente virtual, ¿cómo puedo ayudarte?';
} else {
  // En producción: Los valores vienen de las variables globales
  jwtToken = window.jwtToken;
  divName = window.divName;
  messageWelcome = window.messageWelcome;
}

ReactDOM.render(
  <React.StrictMode>
    <VerifyToken jwtToken={jwtToken}>
      <App jwtToken={jwtToken} divName={divName} messageWelcome={messageWelcome} />
    </VerifyToken>
  </React.StrictMode>,
  document.getElementById(divName) // Aquí usamos el nombre del div dinámicamente
);
