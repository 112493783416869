import React, { useState } from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import ChatView from './ChatView';  // Importa tu componente de chat
import CloseIcon from '@mui/icons-material/Close';

// SVG del ícono de chat
const ChatIcon = () => (
  <svg className="x42-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
  </svg>
);

// Estilo del botón flotante
const FloatingButton = styled(IconButton)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  backgroundColor: '#000', // Fondo negro
  color: '#fff',
  zIndex: 10, // Para asegurarnos que siempre esté visible
  '&:hover': {
    backgroundColor: '#333',
  },
  width: 70,
  height: 70
}));

// Estilo del chat que será flotante y aparezca sobre el botón
const ChatContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(12), // Ajusta esta distancia para que el chat quede justo sobre el botón
  right: theme.spacing(2),
  width: '350px', // Ajusta el tamaño según tus necesidades
  height: '600px', // Ajusta la altura según tu preferencia
  borderRadius: '8px',
  overflow: 'hidden',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // Sombra alrededor del chat
  zIndex: 9, // Justo debajo del botón
  backgroundColor: '#fff', // Fondo blanco para el chat
}));

const ChatApp = ({ jwtToken, divName, messageWelcome }) => {
  const [isOpen, setIsOpen] = useState(false); // Estado para controlar la visibilidad del chat

  // Función para alternar entre mostrar/ocultar el chat
  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const HeaderContainer = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 2,
        backgroundColor: "black"
      }}
    >
      <img src="https://www.flurix.ai/images/Flurix-logo.webp" alt="Flurix Logo" width={50} height={50} />
      <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold', color: 'white' }}>
        Chat de Flurix
      </Typography>
      <IconButton onClick={toggleChat}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  return (
    <div>
      {/* Botón flotante en la esquina inferior derecha */}
      <FloatingButton onClick={toggleChat}>
        <ChatIcon />

      </FloatingButton>

      {/* Mostrar u ocultar el chat basado en el estado */}
      {isOpen && (
        <ChatContainer>
        
          <ChatView  messageWelcome={messageWelcome} jwtToken={jwtToken} toggleChat={toggleChat} /> {/* Componente de Chat importado */}
        </ChatContainer>
      )}
    </div>
  );
};

export default ChatApp;
