import React, { useEffect, useState } from 'react';
import ChatApp from './page/ChatApp';
import { v4 as uuidv4 } from 'uuid';


const App = ({ jwtToken, divName, messageWelcome }) => {

  const createRoomId = async () => {
    let roomIDLocal = localStorage.getItem('roomId');
    if (!roomIDLocal) {
      localStorage.setItem('roomId', uuidv4());
    }
  }
  useEffect(() => {
    createRoomId()
  }, []);

  return (
    <div>
      <ChatApp jwtToken={jwtToken} divName={divName} messageWelcome={messageWelcome} />
    </div>
  );

}
export default App;
