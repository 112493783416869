import React, { createContext, useContext } from 'react';

// Crear el contexto
const CompanyContext = createContext();

// Crear el proveedor del contexto
export const CompanyProvider = ({ companyName, companyLogo, children }) => {
    return (
        <CompanyContext.Provider value={{ companyName, companyLogo }}>
            {children}
        </CompanyContext.Provider>
    );
};

// Hook personalizado para acceder fácilmente al contexto
export const useCompany = () => {
    return useContext(CompanyContext);
};
