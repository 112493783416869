import axios from "axios";

const callApi = async (url, method, token, body) => {


  const responsecall = await axios({
    method: method,
    url: url,
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
  }).catch((error) => {
    if (error.response && error.response.status === 401) {
      // Handle 401 response here
      return { status: false };
    } else {
      // Handle other errors
      return { status: false, session: false };
    }
  });


  if (!responsecall.status) {
    return responsecall;
  } else {
    const json = responsecall.data;
    return json;
  }
};

export const verifyToken = async (token) => {
  const url = `${process.env.REACT_APP_URL}/api/v2/widget/verify`;
  const json = await callApi(url, "GET", token, null);
  return json;
};

export const getAllHistoryPublic = async (requestData, token) => {
  const url = `${process.env.REACT_APP_URL}/api/v2/widget/messages`;
  const json = await callApi(url, "POST", token, requestData);
  return json;
};

export const createClient = async (requestData, token) => {
  const url = `${process.env.REACT_APP_URL}/api/v2/widget/client`;
  const json = await callApi(url, "POST", token, requestData);
  return json;
};


export const getSessionUser = async (token, phone) => {
  const url = `${process.env.REACT_APP_URL}/api/v2/widget/session`;
  const json = await callApi(url, "GET", token, null);
  return json;
};

export const getClienteRecovery = (roomId, token) => {
  const url = `${process.env.REACT_APP_URL}/api/v2/widget/recovery`;
  const json = callApi(url, "POST", token, roomId);
  return json;
};

export const sendMessageApi = async (body, token) => {
  const url = `${process.env.REACT_APP_URL}/api/v2/widget/send`;
  const json = await callApi(url, "POST", token, body);
  return json;
};
