import React, { useState, useEffect, useRef } from 'react';
import { Box, TextField, Button, Paper, Typography, IconButton, InputBase, CircularProgress, LinearProgress } from '@mui/material';
import { styled } from '@mui/system';
import io from 'socket.io-client';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import CloseIcon from '@mui/icons-material/Close';
import { createClient, getAllHistoryPublic, getClienteRecovery, sendMessageApi } from '../service';
import SendIcon from '@mui/icons-material/Send';
import { useCompany } from '../context/CompanyContext';

// Conexión a Socket.IO
const urlLive = process.env.REACT_APP_SOCKET;

// Estilos del contenedor del chat
const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '#f0f0f0',
  borderRadius: '8px',
  overflow: 'hidden',
}));

// Estilo de la lista de mensajes
const MessagesList = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  padding: theme.spacing(2),
  backgroundColor: '#fff',
  borderBottom: '1px solid #ccc',
  overflowWrap: "break-word"
}));

// Estilo del mensaje de usuario
const UserMessage = styled(Paper)(({ theme }) => ({
  alignSelf: 'flex-end',
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  maxWidth: '70%',
  backgroundColor: '#DCF8C6',
  marginInlineStart: 'auto',
  borderRadius: '8px',


}));

// Estilo del mensaje del asistente
const AssistantMessage = styled(Paper)(({ theme }) => ({
  alignSelf: 'flex-start',
  padding: theme.spacing(2),
  margin: theme.spacing(1),
  maxWidth: '70%',
  backgroundColor: '#E8E8E8',
  borderRadius: '8px',
}));

// Contenedor para el input del mensaje
const MessageInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  backgroundColor: '#fff',
  borderTop: '1px solid #ccc',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  padding: '10px',
  borderRadius: '20px',
  border: '1px solid #ccc',
  backgroundColor: '#f0f0f0',
  marginRight: '10px',
  paddingLeft: '15px',
  '&::placeholder': {
    color: '#999',
  },
}));



const ChatView = ({ messageWelcome, jwtToken, toggleChat }) => {
  const { companyName, companyLogo } = useCompany();
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [loadinVerifySession, setLoadinVerifySession] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [clientId, setClientId] = useState(null);
  const [roomId, setRoomId] = useState('');
  const [showForm, setShowForm] = useState(true); // Estado para mostrar el formulario
  const [clientData, setClientData] = useState({
    name: null,
    email: null,
    phone: null,
  });

  const toBase64 = (phone) => {
    const base64Text = btoa(phone);
    return base64Text;
  }

  // Función para obtener o generar un UUID
  const getClientId = () => {
    let clientId = localStorage.getItem('clientId');



    return clientId;
  };


  const recoveryClienRoom = async () => {

    let roomId = localStorage.getItem('roomId');
    const clientIdRedis = await getClienteRecovery({ identifier: roomId }, jwtToken);

    if (clientIdRedis.roomId === roomId) {
      setLoadingHistory(true)
      getAllHistoy(clientIdRedis.cliendId);
      setClientId(clientIdRedis.cliendId);
      setRoomId(roomId);
      setShowForm(false);
      setLoadinVerifySession(false);

    } else {
      setLoadinVerifySession(false);
    }

  }

  useEffect(() => {
    setLoadinVerifySession(true);
    recoveryClienRoom()
  }, []);

  const getRoomId = () => {
    let roomId = localStorage.getItem('roomId');
    if (!roomId) {
      roomId = uuidv4(); // Genera un nuevo roomId si no existe
      localStorage.setItem('roomId', roomId);
    }
    return roomId;
  };


  // Función para manejar el envío del formulario
  const handleFormSubmit = async (e) => {

    e.preventDefault();


    let roomId = localStorage.getItem('roomId');
    const body = {
      name: clientData.name,
      phone: "+" + clientData.phone,
      email: clientData.email,
      identifier: roomId
    };
    //llamamos a api para crear cliente
    const response = await createClient(body, jwtToken);

    if (response.create === "OK") {
      setShowForm(false);
      const clientId = response.cliendId
      setClientId(clientId);
      const roomId = response.roomId
      setRoomId(roomId);
      // addMessageWelcome();
    }
  };

  const addMessageWelcome = () => {
    setMessages((prevMessages) => [...prevMessages, {
      text: messageWelcome,
      user: 'assistant',
    }]);
  };
  // Establecer la conexión a Socket.IO y unirse a la sala
  useEffect(() => {

    if (clientId && roomId) {


      // Conectar a Socket.IO pasando el roomId
      const socket = io(urlLive, {
        query: { clientId }, // Incluye el UUID único en la conexión
        transports: ['websocket'], // Usamos solo WebSockets
      });

      // Evento para la conexión exitosa
      socket.on('connect', () => {
        // console.log(`Conectado al servidor de sockets con UUID: ${clientId} y roomId: ${roomId}`);
        // Unirse a la sala privada
        socket.emit('joinRoom', { roomId });
      });

      // Recibir mensajes del asistente
      socket.on('receiveMessage', (message) => {

        // console.log("message Socket", message)
        const newMessage = {
          text: message.message,
          user: "assistant", // Identificar si el mensaje es del cliente o del asistente
        };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
      });

      socket.on('reconnect_attempt', (attempt) => {
        // console.log(`Intentando reconectar (#${attempt}) con el UUID: ${clientId}`);
      });

      getAllHistoy(clientId);

      return () => {
        socket.off('connect');
        socket.off('receiveMessage');
        socket.off('reconnect_attempt');
      };
    }

  }, [clientId, roomId]);


  async function getAllHistoy(clientId) {
    setMessages([]);
    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
    const response = await getAllHistoryPublic({ identifier: roomId }, jwtToken);

    const history = response.history


    if (history.length === 0) {
      setMessages([]);
      addMessageWelcome()
      setLoadingHistory(false)
      return
    }
    history.forEach((message) => {
      const newMessage = {
        text: message.content,
        user: message.role === 'user' ? 'me' : 'assistant', // Identificar si el mensaje es del cliente o del asistente
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    });
    await delay(2000);
    setLoadingHistory(false)

  }

  // Desplazarse al final del chat cuando haya nuevos mensajes
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const sendMessage = async () => {
    if (message.trim()) {
      const newMessage = {
        text: message,
        user: 'me',
      };
      setMessages([...messages, newMessage]);

      try {
        // Llamada a la API con el UUID del cliente y el mensaje
        const response = await sendMessageApi({ message, identifier: roomId }, jwtToken);

        // const socket = io(urlLive);
        // socket.emit('sendMessage', { roomId, message, caseGTP: 'case-example' });
      } catch (error) {
        console.error('Error enviando el mensaje a la API:', error);
      }

      setMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };


  const HeaderContainer = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: 2,
        backgroundColor: "black"
      }}
    >
      <img src={companyLogo ? companyLogo : 'https://www.flurix.ai/images/Flurix-logo.webp'} alt="Flurix Logo" width={50} height={50} />
      <Typography variant="h6" component="h1" sx={{ fontWeight: 'bold', color: 'white' }}>
        Chat de {companyName}
      </Typography>
      <IconButton onClick={toggleChat}>
        <CloseIcon sx={{ fontSize: 30, color: 'white' }} />
      </IconButton>
    </Box>
  );



  if (loadinVerifySession) {
    //ccreamos un spepinner de carga
    return <CircularProgress />
  }
  // Renderizar formulario si showForm es true
  if (showForm && !clientId && !loadinVerifySession) {
    return (
      <>
        <HeaderContainer />
        <Box
          component="form"
          onSubmit={handleFormSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: 400, // Tamaño máximo uniforme para el contenedor
            width: '90%',
            alignContent: 'center',
            margin: '0 auto',

          }}
        >
          <TextField
            required
            label="Nombre"
            variant="outlined"
            margin="normal"
            fullWidth
            sx={{ height: 50 }} // Altura uniforme
            InputProps={{ sx: { height: 50 } }} // Ajusta la altura del input
            value={clientData.name}
            onChange={(e) => setClientData({ ...clientData, name: e.target.value })}
          />
          <TextField
            required
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            sx={{ height: 50 }} // Altura uniforme
            InputProps={{ sx: { height: 50 } }} // Ajusta la altura del input
            value={clientData.email}
            onChange={(e) => setClientData({ ...clientData, email: e.target.value })}
          />

          <Box
            sx={{
              marginTop: 2,
              marginBottom: 2,
              width: '100%',
            }}
          >
            <PhoneInput
              country={'cl'}
              value={clientData.phone}
              onChange={(phone) => setClientData({ ...clientData, phone })}
              containerStyle={{
                width: '100%',
                height: 50, // Altura uniforme
              }}
              inputStyle={{
                height: 50, // Altura uniforme del input
                width: '100%',
              }}
            />
          </Box>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ height: 50, marginTop: 2, width: '100%' }} // Botón con altura uniforme
          >
            Ingresar
          </Button>
        </Box>
      </>

    );
  }


  // Función para encontrar y convertir URLs en enlaces
  const createLinkInText = (text) => {
    // Expresión regular para detectar URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    if (!text) {
      return
    }
    // Reemplazar cualquier URL detectada con un enlace <a>
    const parts = text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      return part;
    });

    return parts;
  };



  const handleMessage = (message) => {
    setMessage(message);
  }

  const FooterView = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: 2,
          textAlign: 'center',
          maxHeight: 10,
          alignSelf: "center"
        }}
      >
        <Typography sx={{ fontSize: 12, textAlign: "center" }}>
          Usamos Flurix
        </Typography>
        <img src="https://www.flurix.ai/images/Flurix-logo.webp" alt="Flurix Logo" width={30} height={30} style={{ marginLeft: 5 }} />

      </Box>
    );
  }
  // Renderizar el chat si showForm es false
  return (
    <ChatContainer>
      <HeaderContainer />
      {/* Lista de mensajes */}
      <MessagesList>
        {loadingHistory ? <LinearProgress /> :

          messages.map((msg, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              {msg.user === 'me' ? (
                <UserMessage>{createLinkInText(msg.text)}</UserMessage>
              ) : (
                <AssistantMessage>{createLinkInText(msg.text)}</AssistantMessage>
              )}
            </div>
          ))

        }
        <div ref={messagesEndRef} />
      </MessagesList>

      {/* Input para enviar mensajes */}
      <MessageInputContainer>
        {/* Botón de emoji */}


        {/* Campo de entrada de mensaje */}
        <StyledInputBase
          placeholder="Escribe un mensaje..."
          value={message}
          onChange={(e) => {
            handleMessage(e.target.value);
          }}
          onKeyDown={handleKeyPress}
        // onKeyPress={handleKeyPress}
        />

        {/* Botón de enviar */}
        <IconButton onClick={sendMessage} disabled={!message.trim()}>
          <SendIcon style={{ color: message.trim() ? '#007bff' : '#999' }} />
        </IconButton>
      </MessageInputContainer>
      <FooterView />
    </ChatContainer>
  );
};

export default ChatView;
