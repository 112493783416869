import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Snackbar, Alert, CircularProgress, LinearProgress } from '@mui/material';
import { verifyToken } from '../service';
import { CompanyProvider } from './CompanyContext'; 

// Crear el contexto
const VerifyTokenContext = createContext();

// Proveedor del contexto
export const VerifyTokenProvider = ({ jwtToken, children }) => {
    const [isTokenValid, setIsTokenValid] = useState(null); // null = no verificado, true = válido, false = inválido
    const [openSnackbar, setOpenSnackbar] = useState(false); // Para manejar el Snackbar
    const [companyName, setCompanyName] = useState(null);
    const [companyLogo, setCompanyLogo] = useState(null);

    useEffect(() => {
        // Función para verificar el token
        RevisarToken();
    }, [jwtToken]);


    const RevisarToken = async () => {
        try {
            const response = await verifyToken(jwtToken);

            if (response.validate === "ok") {
                setCompanyName(response.dataBusiness.name);
                setCompanyLogo(response.dataBusiness.brand);
                setIsTokenValid(true);
            } else {
                setIsTokenValid(false);
                setOpenSnackbar(true); // Mostrar Snackbar en caso de error
            }
        } catch (error) {
            console.error('Error verificando el token:', error);
            setIsTokenValid(false);
            setOpenSnackbar(true); // Mostrar Snackbar en caso de error
        }

    }
    if (isTokenValid === null) {
        //creamos un skeleton de cargando
        const skeleton =   <LinearProgress />
        return skeleton
        // Mostrar un indicador de carga mientras se verifica el token
    }

    return (
        <VerifyTokenContext.Provider value={{ isTokenValid }}>

            <CompanyProvider companyName={companyName} companyLogo={companyLogo}>
                {children}
            </CompanyProvider>

            {/* Snackbar para mostrar el error de token */}
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                <Alert onClose={() => setOpenSnackbar(false)} severity="error" sx={{ width: '100%' }}>
                    Error: Token Chat inválido o caducado.
                </Alert>
            </Snackbar>
        </VerifyTokenContext.Provider>
    );
};

// Hook para acceder al contexto
export const useVerifyToken = () => {
    return useContext(VerifyTokenContext);
};

// Componente que usa el contexto
const VerifyToken = ({ jwtToken, children }) => {
    return <VerifyTokenProvider jwtToken={jwtToken}>{children}</VerifyTokenProvider>;
};

export default VerifyToken;
